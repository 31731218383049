import styled from "styled-components";
import tw from "tailwind-styled-components";

export const FooterWrapper = styled.footer`
  width: 100%;
  padding: 0 var(--footerPadding);
  padding-bottom: 50px;
  margin: 0 auto;

  @media screen and (min-width: 1128px) {
    max-width: calc(1128px + (var(--footerPaddingDesktop) * 2));
    padding: var(--footerPaddingDesktop);
    padding-bottom: var(--fixed-ctas-height);
    margin-top: calc(var(--sectionSpacingDesktop) * -1);
  }
`;

export const ImagesContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing2Xl);
  justify-content: center;

  > img {
    width: auto;
    max-height: 70px;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: nowrap;
    gap: 2rem;
    justify-content: unset;
    overflow-x: auto;
  }
`;

export const FootContentWrapper = styled.div`
  border-top: 1px solid var(--gray200);
  padding-top: var(--sectionSpacing);
  margin: 0 var(--spacingXl);

  @media screen and (min-width: 1128px) {
    max-width: calc(1128px + (var(--mainPaddingDesktop) * 2));
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--spacingL);
    margin: 0 auto;
  }
`;

export const CopyrightMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 360px;
  font-size: var(--textS);
  line-height: var(--lineHeightS);
  padding: 0px;
  margin: auto;

  p {
    margin: 0px;
  }
`;

export const ColumnTitle = styled.h3`
  font-size: var(--textL);
  font-weight: normal;
  padding: 0px;
  margin: 0px;
  line-height: var(--lineHeightL);
  margin-bottom: var(--spacingM);
`;

export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacingM);
  font-size: var(--textS);
  line-height: var(--lineHeightS);

  ${({ whGap }) => whGap && "gap: 0;"}

  p {
    margin: 0 !important;
  }

  a {
    text-decoration: underline;
    text-underline-offset: 2px;
    appearance: none;
    background: none;
    padding: 0;
    cursor: pointer;
    border: 0;
    color: var(--gray) !important;
  }
`;

export const FooterSectionWrapper = styled.div`
  margin-bottom: var(--sectionSpacing);
`;

export const Space = styled.div`
  margin-top: 24px;
`;

export const FooterContentLine = tw.div`
  flex
  flex-col
  justify-center
  items-center
  border-t
  border-t-gray-200
  py-10
  mx-10 desktop:mx-0
`;

export const RRSSWrapper = tw.div`
  flex
  flex-row
  gap-2
`;

export const RRSSIconWrapper = tw.div`
  flex
  justify-center
  items-center
  rounded-full
  bg-gray-700
  w-9
  h-9
`;

export const OfficesLinksWrapper = tw.div`
  flex
  flex-col
  gap-3
`;

export const OfficeWrapper = tw.div`
  flex
  flex-row
  gap-2
`;
